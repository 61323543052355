
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { Employee } from '@/entities/hr'

@Component({
  components: { Avatar: () => import('./EmployeeAvatar.vue') },
})
  export default class EmployeeStatus extends Vue {
  @Prop({ type: Object, required: true }) user!: Employee
  @Prop({ type: String, default: 'blue darken-1' }) color!: string
  @Prop({ type: Number, default: 0 }) total!: number
  @Prop({ type: Number, default: null }) idProcess: number

  get aggregates () {
    const { user: { aggregates } } = this
    return aggregates || []
  }

  get progress (): number {
    const { total, user: { aggregates } } = this
    return aggregates.aggregate('assigned').value / total * 100
  }

  get baseColor (): string {
    const { color } = this
    return color.split(' ').shift()
  }
  }
